import React, { useEffect, useState } from 'react'

import { ArPlatformProducts } from 'src/components/ArPlatformProducts'
import { ArRichCtaWithBg } from 'src/components/ArRichCtaWithBg'
import { ArSeo } from 'src/components/ArSeo'
import HomepageFooterCtaBg from 'src/assets/images/HomepageFooterCtaBg.jpg'
import HomepageFooterCtaBgWebp from 'src/assets/images/HomepageFooterCtaBg.webp'
import { METRICS } from 'src/config/metrics'
import { ROUTES } from 'src/config/routes'
import { isWebpSupported } from 'src/utils/isWebpSupported'
import { navigate } from 'gatsby'
import { ArInsurersHero } from 'src/components/ArInsurersHero'
import { ArInsurersCustomerLove } from '../components/ArInsurersCustomerLove'
const PlatformPage = () => {
  const [footerCtaBgUrl, setFooterCtaBgUrl] = useState<string>()

  useEffect(() => {
    setFooterCtaBgUrl(isWebpSupported() ? HomepageFooterCtaBgWebp : HomepageFooterCtaBg)
  }, [])

  return (
    <>
      <ArSeo route={ROUTES.platform()} />
      <ArInsurersHero />
      <ArPlatformProducts />
      <ArInsurersCustomerLove />
      <ArRichCtaWithBg
        backgroundUrl={footerCtaBgUrl}
        heights={{
          mobile: METRICS.FooterHeightMobile,
          desktop: METRICS.FooterHeightMobile,
        }}
        title={[`Want to learn more?`]}
        subtitle="Book a chat with us today to discover how our technology could help transform your specialty (re)insurance business."
        actionButtons={[
          {
            title: 'Contact us',
            onClick: () => {
              navigate(ROUTES.contact())
            },
            theme: 'dark',
          },
        ]}
        withDarkGradient
      />
    </>
  )
}

export default PlatformPage

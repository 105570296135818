import React from 'react'
import { TArPlatformProductsProps } from './ArPlatformProducts.types'
import { ArFeatureBlock } from 'src/components/ArFeatureBlock'
import insurersImage1 from 'src/assets/images/Insurers-image-1.png'
import insurersImage2 from 'src/assets/images/Insurers-image-2.png'
import insurersImage3 from 'src/assets/images/Insurers-image-3.png'
import insurersImage4 from 'src/assets/images/Insurers-image-4.png'
import insurersImage5 from 'src/assets/images/Insurers-image-5.png'

export const ArPlatformProducts: React.FC<TArPlatformProductsProps> = () => {

  return (
    <>
      <ArFeatureBlock
        title="Designed for every strategy"
        description={
          <p>
            Every product we create is designed specifically for your strategy to enhance and safeguard the value of your team’s underwriting IP. Our tools are uniquely tailored to your needs—not a one-size-fits-all solution.
            <br/>
            <br/>
            Whether you underwrite as a leader, follower or on a portfolio or delegated basis, our tools seamlessly adapt to every placement method and market participant.
          </p>
        }
        imageUrl={insurersImage1}
        imageAlt="Screenshot of artificial's broker tool user interface"
        hasGrayBackground={true}
      />

      <ArFeatureBlock
        title="Real-time portfolio management"
        description={
          <p>
            Design portfolios according to your target characteristics to achieve your desired outcomes and manage risk effectively. Automatically evaluate incoming risks against your target portfolio to maintain alignment, reduce inefficiencies, and support better decision-making.
            <br/>
            <br/>
            Run advanced scenarios to uncover opportunities, refine your underwriting strategies, and optimise performance while ensuring your portfolio remains resilient.
          </p>
        }
        imageUrl={insurersImage2}
        imageAlt="Screenshot of artificial's broker tool user interface"
        isImageLeft={true}
      />

      <ArFeatureBlock
        title="Powerful triage and workflow"
        description={
          <p>
            Underwrite anytime, anywhere, with automated submission triaging and real-time scoring. Evaluate risks automatically against underwriting criteria, ensuring consistency and alignment with your target portfolio and underwriting strategy.
            <br/>
            <br/>
            Leverage powerful workflows that automate tasks, checks, and compliance, freeing up valuable time to focus on high-value underwriting activities.
          </p>
        }
        imageUrl={insurersImage3}
        imageAlt="Screenshot of artificial's broker tool user interface"
        hasGrayBackground={true}
      />

      <ArFeatureBlock
        title="Flexible pricing modules"
        description={
          <p>
            Spend more time underwriting and less on manual tasks with seamless, instant pricing directly within our platform.
            <br/>
            <br/>
            Easily integrate your existing pricing models, collaborate with our team to build a tailored solution, or connect with digital pricing tools for a fully connected pricing workflow.
          </p>
        }
        imageUrl={insurersImage4}
        imageAlt="Screenshot of artificial's broker tool user interface"
        isImageLeft={true}
      />

      <ArFeatureBlock
        title="Future-proof data"
        description={
          <p>
            The future of distribution is API-driven. But for now, our advanced AI-powered data ingestion and enrichment tools are creating efficiencies for carriers and distributors. We automatically capture, structure and enrich risk data from emails, PDFs, spreadsheets and Word documents.
            <br/>
            <br/>
            Bridge the gap between today and tomorrow by eliminating hours of manual entry while preparing your data records for the future.
          </p>
        }
        imageUrl={insurersImage5}
        imageAlt="Screenshot of artificial's broker tool user interface"
        hasGrayBackground={true}
      />
    </>
  )
}

import React from 'react'
import { ArHero } from 'src/components/ArHero'
import InsurersPageScreenshot from 'src/assets/images/UnderwritingWorkbench.png'
import LogoArdonagh from 'src/assets/svg/logo-ardonagh.svg'
import LogoAxis from 'src/assets/svg/logo-axis.svg'
import LogoChaucer from 'src/assets/svg/logo-chaucer.svg'
import LogoConvex from 'src/assets/svg/logo-convex.svg'
import { ROUTES } from 'src/config/routes'
import ApolloLogo from '../../assets/images/apollo-logo.png'

export const ArInsurersHero: React.FC = () => {

  const logosNode = (
    <div className="max-w-5xl mx-auto grid md:grid-cols-5 grid-cols-2 pt-12 md:pt-0 justify-items-center items-center md:gap-15">
      <LogoAxis aria-label="Axis logo"/>
      <LogoChaucer aria-label="Chaucer logo"/>
      <LogoConvex aria-label="Convex logo"/>
      <div className="w-32">
        <img src={ApolloLogo} alt="Apollo logo" />
      </div>
      <LogoArdonagh className="col-span-2 md:col-span-1" aria-label="Ardonagh insurance logo" />
    </div>
  )

  return (
    <ArHero
      title="The future of underwriting"
      description={
        <p className="font-medium">
            Artificial's <span className="font-semibold">Smart Underwriting</span> tools enable carriers to deploy underwriting capital to lead and follow opportunities in the most effective way.
        </p>
      }
      buttonText="Get in touch"
      buttonHref={ROUTES.contact()}
      heroImageSrc={InsurersPageScreenshot}
      heroImageAlt="Screenshot of artificial's underwriting platform interface"
      logosTitle="TRUSTED BY SOME OF THE WORLD'S BEST CARRIERS"
      logosNode={logosNode}
    />
  )
}

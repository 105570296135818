// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArInsurersCustomerLove-module--ar-gradient-blue--S3CUp";
export var arGradientLight = "ArInsurersCustomerLove-module--ar-gradient-light--N9X7o";
export var arH1 = "ArInsurersCustomerLove-module--ar-h1--RcxDE";
export var arH1Mobile = "ArInsurersCustomerLove-module--ar-h1-mobile--2Kk66";
export var arH2 = "ArInsurersCustomerLove-module--ar-h2--cJIWp";
export var arH2Mobile = "ArInsurersCustomerLove-module--ar-h2-mobile--TOkVs";
export var arH3 = "ArInsurersCustomerLove-module--ar-h3--Dv82+";
export var arH3Mobile = "ArInsurersCustomerLove-module--ar-h3-mobile--DPPNM";
export var arH4 = "ArInsurersCustomerLove-module--ar-h4--MruTL";
export var arH4Mobile = "ArInsurersCustomerLove-module--ar-h4-mobile---hOF0";
export var arH5 = "ArInsurersCustomerLove-module--ar-h5--7jlyQ";
export var arH5Mobile = "ArInsurersCustomerLove-module--ar-h5-mobile--AREGc";
export var arLinkDesktop = "ArInsurersCustomerLove-module--ar-link-desktop--00Hh5";
export var arLinkMobile = "ArInsurersCustomerLove-module--ar-link-mobile--L3fAF";
export var arParagraphBase = "ArInsurersCustomerLove-module--ar-paragraph-base--MyFHA";
export var arParagraphBaseMedium = "ArInsurersCustomerLove-module--ar-paragraph-base-medium--ilYcd";
export var arParagraphSmall = "ArInsurersCustomerLove-module--ar-paragraph-small--jtZOw";
export var arParagraphSmallMedium = "ArInsurersCustomerLove-module--ar-paragraph-small-medium--KXCgb";
export var arParagraphXl = "ArInsurersCustomerLove-module--ar-paragraph-xl--fWGDq";
export var arParagraphXlMedium = "ArInsurersCustomerLove-module--ar-paragraph-xl-medium--bOYZp";
export var arParagraphXsmall = "ArInsurersCustomerLove-module--ar-paragraph-xsmall--YUFTc";
export var arParagraphXsmallMedium = "ArInsurersCustomerLove-module--ar-paragraph-xsmall-medium--xEYKB";
export var arQuote = "ArInsurersCustomerLove-module--ar-quote--DGcAW";
export var arQuoteMobile = "ArInsurersCustomerLove-module--ar-quote-mobile--yQbmz";
export var arTransition = "ArInsurersCustomerLove-module--ar-transition--pMQPU";
export var container = "ArInsurersCustomerLove-module--container--zbdql";
export var footer = "ArInsurersCustomerLove-module--footer--k76PL";
export var logo = "ArInsurersCustomerLove-module--logo--PAvt0";
export var quotation = "ArInsurersCustomerLove-module--quotation--tzHUv";
import * as s from './ArInsurersCustomerLove.module.scss'

import { ArContainer } from 'src/components/ArContainer'
import React from 'react'
import ApolloLogo from 'src/assets/images/apollo-logo.png'
import { TArInsurersCustomerLoveProps } from './ArInsurersCustomerLove.types'

export const ArInsurersCustomerLove: React.FC<TArInsurersCustomerLoveProps> = () => {
  return (
    <ArContainer className={s.container} full>
      <h6>Loved by our customers</h6>
      <div className={s.quotation}>
        <h3>
          <span>
            <div>“</div>
          </span>
          Artificial's technology is propelling us into the next phase of our evolution.
          <span>
            <div>”</div>
          </span>
        </h3>
        <div className="w-32">
          <img src={ApolloLogo} alt="Apollo logo" className={s.logo} />
        </div>
        <p className={s.footer}>
          <span>David Ibeson</span> CEO of Apollo Group Holdings
        </p>
      </div>
    </ArContainer>
  )
}
